import { combineReducers } from 'redux';
import auth from './modules/auth/auth.reducer';
import layout from './modules/layout/layout.reducer';

import answers from '@survey/common/dist/reducers/answers.reducer';
import questions from '@survey/common/dist/reducers/questions.reducer';
import surveys from '@survey/common/dist/reducers/surveys.reducer';
import pages from '@survey/common/dist/reducers/pages.reducer';
import tabs from '@survey/common/dist/reducers/tabs.reducer';
import technologies from '@survey/common/dist/reducers/technologies.reducer';
import products from '@survey/common/dist/reducers/products.reducer';
import vendors from '@survey/common/dist/reducers/vendors.reducer';
import entities from '@survey/common/dist/reducers/entities.reducer';
import organizations from '@survey/common/dist/reducers/organizations.reducer';
import countries from '@survey/common/dist/reducers/countries.reducer';
import delegates from '@survey/common/dist/reducers/delegates.reducer';
import resources from '@survey/common/dist/reducers/resources.reducer';
const rootReducer = combineReducers({
  layout,
  auth,
  questions,
  surveys,
  pages,
  tabs,
  technologies,
  products,
  vendors,
  answers,
  organizations,
  countries,
  entities,
  delegates,
  resources
});

export default rootReducer;
