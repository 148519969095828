import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import MainLayout from './modules/layout/main.layout';

const Root = ({ store, persistor }) => (
  <Provider store={store}>
    <PersistGate loading={<div className="loading" />} persistor={persistor}>
      <Route path="/" component={MainLayout} />
    </PersistGate>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired
};
export default Root;
