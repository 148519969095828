import React, { Component } from 'react';

export const withRouteOnEnter = callback => BaseComponent => {
  const routeOnEnterCallback = props => {
    if (callback && typeof callback === 'function') {
      callback(props);
    }
  };

  class routeOnEnterComponent extends Component {
    constructor(props){
      super(props);
      routeOnEnterCallback(props);
    }

    componentDidUpdate(prevProps) {
      if (prevProps.location !== this.props.location) {
        routeOnEnterCallback(this.props);
      }
    }

    render() {
      return <BaseComponent {...this.props} />;
    }
  }

  return routeOnEnterComponent;
};
