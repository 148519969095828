import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import { HIMSSBlue, HIMSSDarkBlue, SilverSand } from './modules/layout/styles/colors';
import { unregister } from './registerServiceWorker';
import Root from './root.container';
import configureStore from './store/configureStore';
import { createRoot } from 'react-dom/client';

const theme = createTheme({
  palette: {
    primary: HIMSSDarkBlue,
    secondary: HIMSSBlue,
    grey: SilverSand,
  },
  typography: {
    fontSize: 12,
    fontFamily: '"Verlag Bold", "Helvetica", "Arial", sans-serif',
  },
  overrides: {
    MuiInputLabel: {
      marginDense: {
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left'
      }
    },
    icon: {
      root: {
        fill: '#1a25ab !important',
      },
    },
    MuiListItem: {
      root: {
        color: '#1a25ab !important',
      },
    },
    MuiStepLabel: {
      label: {
        '$active &': {
          fontWeight: 'bold',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '20px',
        minWidth: '7.4rem',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#35C37D',
        },
      },
      containedPrimary: {
        boxShadow: 'none',
        minWidth: '7.4rem',
        '&:hover': {
          backgroundColor: '#4dc0e8 !important',
        },
      },
    },
    MuiInputBase: {
      input: {
        height: ''
      }
    }
  },
  components: {
    MuiTextField: {
      defaultProps:{
        variant: 'standard'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    }
  }
});
const drTheme = createTheme({
  palette: {
    primary: { main: '#005596' },
    secondary: { main: '#29abe3' },
    grey: SilverSand,
  },
  typography: {
    fontSize: 12,
    fontFamily: "'Century Gothic', CenturyGothic, AppleGothic, sans-serif",
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontWeight: 700,
      },
    },
    MuiTypography: {
      body2: {
        fontWeight: 700,
      },
      h6: {
        fontWeight: 700,
      },
    },
    icon: {
      root: {
        fill: '#005596 !important',
      },
    },
    MuiListItem: {
      root: {
        color: '#005596 !important',
      },
    },
    MuiStepLabel: {
      label: {
        '$active &': {
          fontWeight: 'bold',
        },
      },
    },
    MuiInput: {
      icon: {
        fill: '#005596 !important',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '20px',
        minWidth: '7.4rem',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#29abe3',
        },
      },
      containedPrimary: {
        boxShadow: 'none',
        minWidth: '7.4rem',
        '&:hover': {
          backgroundColor: '#29abe3',
        },
      },
    },
    MuiInputBase: {
      input: {
        height: ''
      }
    }
  },
  components: {
    MuiTextField: {
      defaultProps:{
        variant: 'standard'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    }
  }
});
//There should be a better way
let $_GET = {};
if (document.location.toString().indexOf('?') !== -1) {
  var query = document.location
    .toString()
    // get the query string
    .replace(/^.*?\?/, '')
    // and remove any existing hash string (thanks, @vrijdenker)
    .replace(/#.*$/, '')
    .split('&');

  for (var i = 0, l = query.length; i < l; i++) {
    var aux = decodeURIComponent(query[i]).split('=');
    $_GET[aux[0]] = aux[1];
  }
}

console.log('REACT VERSION: ', React.version);
const path = document.location.pathname.replace('/', '').toLowerCase();

if (process.env.REACT_APP_AUTH0_USE_AUTH === 'false') {
  let accessPin = sessionStorage.getItem('HAAccessPin');

  //TODO: WE STILL NEED A REDUX API CALLBACK FOR A FAILED 401
  //get the pin if in the URL
  if ($_GET['pin']) {
    sessionStorage.setItem('HAAccessPin', $_GET['pin']);
    accessPin = $_GET['pin'];
  } else {
    //if the pin is already stored
    if (!accessPin && path != 'privacy' && path != 'imprint' && path != 'terms') {
      //causes a popup for every request off the bat...
      let pinConfirmation = prompt('Please enter your security PIN');

      //NEED TO FIGURE OUT A WAY TO PICKUP THE 401 RESPONSE
      if (!pinConfirmation) {
        //alert('Sorry that PIN does not match our records');
      } else {
        sessionStorage.setItem('HAAccessPin', pinConfirmation);
      }
    }
  }
}

function startApp() {
  let env = 'Environment Variable: ' + process.env.REACT_APP_ENVIRONMENT;
  let textArt = `
    ........................................................................................................................................................................................................
    ........................................................................................................................................................................................................
    ........................................................................................................................................................................................................
    ............................@@.....@@@.....@............................................................................................................................................................
    .....................%@.....@@%............@@...........................................................................................................................................................
    .....................@@@..........................@@....................................................................................................................................................
    ...............@@@.................@@@..................@@..............................................................................................................................................
    ................%...........@@@...........@@@...........................................................................................................................................................
    ..........@@@........@@@@........................@@%.........@..........................................................................................................................................
    ..........@@@...................@@.....@@....................@..........................................................................................................................................
    ................@@@@............@@.....@@.............@@%...............................................................................................................................................
    ......@@@@...............@@@.................@@@................@@...................@@@@@@............@@@@@@.......@@@@@@.......@@.....................@........@@@@@@@@@@@@@@........@@@@@@@@@@@@@....
    .............@@@.....................................................................@@@@@@............@@@@@@.......@@@@@@.......@@@@................@@@@......@@@@@@@@@@@@@@........@@@@@@@@@@@@@@.....
    .............@@@....@@@@..........................@@......@..........................@@@@@@............@@@@@@.......@@@@@@.......@@@@@@............@@@@@@......@@@@@@................@@@@@@.............
    ....@@@@..............@...........................................@@.................@@@@@@............@@@@@@.......@@@@@@.......@@@@@@@@@.......@@@@@@@@......@@@@@@@@..............@@@@@@@@...........
    ............@@@............................................@.........................@@@@@@@@@@@@@@@@@@@@@@@@.......@@@@@@.......@@@@@@@@@@@...@@@@@@@@@@.......@@@@@@@@@@@@@.........@@@@@@@@@@@@@.....
    ............@@@....@@@@%..........................%@@................................@@@@@@@@@@@@@@@@@@@@@@@@.......@@@@@@.......@@@@@@@@@@@@@@@@@@@@@@@@...........@@@@@@@@@@@..........@@@@@@@@@@@@...
    ....@@@@..........................................................@@.................@@@@@@............@@@@@@.......@@@@@@.......@@@@@@..@@@@@@@@@.@@@@@@................@@@@@@@...............@@@@@@@..
    .............@@@@......@@................................@@..........................@@@@@@............@@@@@@.......@@@@@@.......@@@@@@....@@@@....@@@@@@.......@........@@@@@@@......@........@@@@@@...
    .......@@.....@%......@@@@......................@@...................................@@@@@@............@@@@@@.......@@@@@@.......@@@@@@............@@@@@@......@@@@@@@@@@@@@@@@.....@@@@@@@@@@@@@@@@@...
    ......@@@@.......@@.........@@@.................................@@...................@@@@@@............@@@@@@.......@@@@@@.......@@@@@@.............@@@@@......@@@@@@@@@@@@@@........@@@@@@@@@@@@@@.....
    ................@@@@.......@@@@@..@@@@@....@..........@@................................................................................................................................................
    .........@@@@.........@@..........%@@@@......................@..........................................................................................................................................
    ..........@@@........@@@@........................@@.....................................................................................................................................................
    ..............@@@@.........@@@@@..%@@@@....@%...........................................................................................................................................................
    ..............@@@@...........@....@@@@@.................................................................................................................................................................
    ....................@@@@@.........................@@....................................................................................................................................................
    .....................@@....@@@@%...@@@%....@%...........................................................................................................................................................
    ............................@@@...@@@@@.................................................................................................................................................................
    ........................................................................................................................................................................................................
    ........................................................................................................................................................................................................`;
  console.log(env);
  console.log(textArt);
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <ThemeProvider theme={process.env.REACT_APP_ENVIRONMENT === 'germantender' ? drTheme : theme}>
        <Router>
          <Root store={storeConfig.store} persistor={storeConfig.persistor} />
        </Router>
    </ThemeProvider>    
  );
}

// injectTapEventPlugin();
const storeConfig = configureStore();

startApp();

unregister();
