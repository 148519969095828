import { Paper, Typography } from '@mui/material';
import { withStyles } from "@mui/styles";
import PropTypes from 'prop-types';
import React from 'react';
import { getResource } from '@survey/common/dist/utilities/getResource';

const isGermanTenderEnv = process.env.REACT_APP_ENVIRONMENT === 'germantender';

const styles = () => ({
  image: {
    height: '100% !important',
    width: '100% !important',
  },
  bodyText: {
    color: '#000 !important',
    lineHeight: '1.5rem !important',
    fontSize: '1.2rem !important',
    marginTop: '10px !important',
    fontWeight: "bold !important"
  },
  paper: {
    borderRadius: 0,
    boxShadow: '0px 0px 20px -2px rgba(0, 0, 0, .75) !important',
    display: 'flex !important',
    flexDirection: 'column !important',
    height: 'auto !important',
    padding: '1rem !important',
    justifyContent: 'space-between !important',
  },
  wrapper: {
    marginTop: isGermanTenderEnv ? '11rem' : 'inherit',
    marginBottom: isGermanTenderEnv ? '0rem !important' : '3rem !important',
    position: 'relative !important',
    width: '100% !important',
    top: isGermanTenderEnv ? 'inherit': '0'
  },
});

const Widget = ({ classes, widget, resources, language }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.widgetContainer}>
        <Paper className={classes.paper} style={{ textAlign: 'center' }}>
          {widget.image && widget.image.length && !isGermanTenderEnv > 0 && <img style={{}} src={widget.image} alt="" />}
          <Typography variant="body2" className={classes.bodyText}>
            <div dangerouslySetInnerHTML={{ __html: getResource(resources, language, widget.content, '') }}></div>
          </Typography>
        </Paper>
      </div>
    </div>
  );
};

Widget.propTypes = {
  widget: PropTypes.object.isRequired,
};

export default withStyles(styles)(Widget);
