import { Grid, Hidden } from '@mui/material';
import { withStyles } from "@mui/styles";
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LeftPanel from '../../images/Left-Panel.png';
import LeftPanelDR from '../../images/Left-PanelDR_new.png';

const styles = theme => ({
  root: {
    backgroundColor: '#fff',
  },
  panel: {
    alignItems: 'center',
    backgroundImage: `url(${LeftPanel})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    paddingBottom: '2rem',
    height: '100%',
    backgroundColor: '#1526a5',
    marginRight: '1em',
    minHeight: '2564px',
  },
  drpanel: {
    backgroundImage: `url(${LeftPanelDR})`,
    backgroundColor: '#005491',
  },
});

class Privacy extends Component {
  render() {
    const { classes } = this.props;
    const isGermanTenderEnv = process.env.REACT_APP_ENVIRONMENT === 'germantender';

    return (
      <div className={classes.root}>
        <Grid container>
          <Hidden xsDown>
            <Grid item sm={3}>
              <div className={`${classes.panel} ${isGermanTenderEnv ? classes.drpanel : ''}`}></div>
            </Grid>
          </Hidden>
          <Grid item sm={9} xs={12}>
            <h1>Datenschutzerklärung</h1>

            <p>
              Diese Datenschutzerklärung soll die Nutzer:innen der Website <a href="https://www.digitalradar-krankenhaus.de/">https://www.digitalradar-krankenhaus.de/</a> 
              über die Art, den Umfang und den Zweck der Erhebung und Verwendung erhobener Daten durch das Konsortium DigitalRadar vertreten durch HIMSS Europe GmbH/ 
              inav - privates Institut für angewandte Versorgungsforschung GmbH/ Lohfert & Lohfert AG informieren. Zudem werden von der Verarbeitung ihrer personenbezogenen 
              Daten betroffene Personen mittels dieser Datenschutzerklärung über die ihnen zustehenden Rechte aufgeklärt.
            </p>
            <p>
              Eine Nutzung der Internetseiten des Konsortium DigitalRadar (<a href="https://www.digitalradar-krankenhaus.de">https://www.digitalradar-krankenhaus.de</a>) ist grundsätzlich ohne jede 
              Angabe personenbezogener Daten möglich. Sofern eine betroffene Person angebotene Services über unsere Internetseite in Anspruch nehmen möchte, 
              könnte jedoch eine Verarbeitung personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und besteht 
              für eine solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung der betroffenen Person ein. Die Verarbeitung 
              personenbezogener Daten erfolgt stets im Einklang mit der Datenschutz-Grundverordnung (DSGVO) und in Übereinstimmung mit den für das Konsortium 
              DigitalRadar geltenden landesspezifischen Datenschutzbestimmungen.
            </p>
            <p>
              Durch die ständige Weiterentwicklung dieser Webseite, können Änderungen an dieser Datenschutzerklärung vorgenommen werden. 
              Wir empfehlen Ihnen daher unsere Datenschutzerklärung in regelmäßigen Abständen auf Änderungen zu prüfen.
            </p>
            <p>
              Das Konsortium DigitalRadar hat als für die Verarbeitung gemeinsam Verantwortlicher zahlreiche technische und 
              organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über diese Internetseite verarbeiteten 
              personenbezogenen Daten sicherzustellen. Dennoch können Internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken 
              aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es jeder betroffenen Person frei, 
              personenbezogene Daten auch auf alternativen Wegen, beispielsweise telefonisch, an uns zu übermitteln.
            </p>
            <p>
              Die HIMSS Europe GmbH ist eine internationale Organisation mit Mutterkonzern in den USA, die im Rahmen der Offenlegungspflicht 
              gegebenenfalls Daten an Drittstaaten außerhalb der EU übermitteln muss. Dies findet aber zu keinem anderen Zweck außer den in dieser 
              Datenschutzerklärung explizit genannten Zwecken statt.
            </p>
            <h2>1. Begriffsbestimmungen</h2>
            <p>
              Die Datenschutzerklärung des Konsortium DigitalRadar beruht auf den Begrifflichkeiten, die durch den Europäischen Richtlinien- 
              und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DSGVO) verwendet wurden. Unsere Datenschutzerklärung soll sowohl 
              für die Öffentlichkeit als auch für unsere Kunden:innen und Geschäftspartner:innen einfach lesbar und verständlich sein. Um dies zu 
              gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern.
            </p>
            <p>
              Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:
            </p>
            <h3>1.1. personenbezogene Daten</h3>
            <p>
              Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person 
              (im Folgenden „betroffene Person“) beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, 
              insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder 
              zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, 
              kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.
            </p>
            <h3>1.2. betroffene Person</h3>
            <p>
              Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene Daten von der/ 
              dem für die Verarbeitung Verantwortlichen verarbeitet werden.
            </p>
            <h3>1.3. Verarbeitung</h3>
            <p>
              Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe 
              im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, 
              die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, 
              Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
            </p>
            <h3>1.4. Einschränkung der Verarbeitung</h3>
            <p>
              Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken.
            </p>
            <h3>1.5. Verantwortlicher oder für die Verarbeitung Verantwortlicher</h3>
            <p>
              Der/ die Verantwortliche oder für die Verarbeitung Verantwortliche ist die natürliche oder juristische Person, Behörde, 
              Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen 
              Daten entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, 
              so kann die/ der Verantwortliche beziehungsweise können die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der 
              Mitgliedstaaten vorgesehen werden.
            </p>
            <h3>1.6. Auftragsverarbeiter:in</h3>
            <p>
              Auftragsverarbeiter:in ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene 
              Daten im Auftrag der/ des Verantwortlichen verarbeitet.
            </p>
            <h3>1.7. Empfänger</h3>
            <p>
              Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, der personenbezogene 
              Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder nicht. Behörden, die im Rahmen eines 
              bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, 
              gelten jedoch nicht als Empfänger.
            </p>
            <h3>1.8. Dritter</h3>
            <p>
              Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer der betroffenen Person, 
              der/ dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung der/ des Verantwortlichen 
              oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.
            </p>
            <h3>1.9. Einwilligung</h3>
            <p>
              Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in informierter Weise und unmissverständlich 
              abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person 
              zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.
            </p>
            <h2>2. Name und Anschrift des für die Verarbeitung Verantwortlichen</h2>
            <p>
              Verantwortlicher im Sinne der Datenschutz-Grundverordnung (DSGVO), sonstiger in den Mitgliedstaaten der Europäischen Union 
              geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist das:
            </p>
            <ul style={{listStyleType:"none"}}>
                <li>Konsortium DigitalRadar</li>
                <li>Bertha-Benz-Straße 5</li>
                <li>10557 Berlin</li>
            </ul>
            <p>
              vertreten durch:
            </p>
            <ul style={{listStyleType:"none"}}>
                <li>HIMSS Europe GmbH</li>
                <li>Bertha-Benz-Straße 5</li>
                <li>10557 Berlin</li>
                <li><a href="mailto:HIMSS_International@himss.org">HIMSS_International@himss.org</a></li>
            </ul>
            <p>
              &nbsp;
            </p>
            <ul style={{listStyleType:"none"}}>
                <li>inav - privates Institut für angewandte Versorgungsforschung GmbH</li>
                <li>Schiffbauerdamm 12</li>
                <li>10117 Berlin</li>
                <li><a href="mailto:info@inav-berlin.de">info@inav-berlin.de</a></li>
            </ul>
            <p>
              &nbsp;
            </p>
            <ul style={{listStyleType:"none"}}>
                <li>Lohfert &amp; Lohfert AG</li>
                <li>Rothenbaumchaussee 76</li>
                <li>20148 Hamburg</li>
                <li><a href="mailto:Datenschutz@lohfert.net">Datenschutz@lohfert.net</a></li>
            </ul>
            <h2>3. Name und Anschrift des Datenschutzbeauftragten</h2>
            <p>Der Datenschutzbeauftragte des für die Verarbeitung Verantwortlichen ist:</p>
            <ul style={{listStyleType:"none"}}>
                <li>Christian Baginski</li>
                <li>Rothenbaumchaussee 76</li>
                <li>20148 Hamburg</li>
                <li>Tel.: +49 40 41906 702</li>
                <li>Email: <a href="mailto:Datenschutz@lohfert.net">Datenschutz@lohfert.net</a></li>
                <li>Website: <a href="www.lohfert.net">www.lohfert.net</a></li>
            </ul>
            <h2>4. Kontaktmöglichkeiten mit dem Konsortium Digitalradar</h2>
            <p>
              Die Internetseite des Konsortium DigitalRadar enthält aufgrund von gesetzlichen Vorschriften Angaben, die eine schnelle elektronische Kontaktaufnahme 
              sowie eine unmittelbare Kommunikation mit uns ermöglichen, was ebenfalls eine allgemeine Adresse der sogenannten elektronischen Post (E-Mail-Adresse) umfasst. 
              Sofern eine betroffene Person den Kontakt mit dem für die Verarbeitung Verantwortlichen aufnimmt, werden die von der betroffenen Person 
              übermittelten personenbezogenen Daten automatisch gespeichert. Solche auf freiwilliger Basis von einer betroffenen Person an den für die 
              Verarbeitung Verantwortlichen übermittelten personenbezogenen Daten werden für Zwecke der Bearbeitung oder der Kontaktaufnahme zur betroffenen Person gespeichert.
            </p>
            <h3>4.1. Kontaktaufnahme per Mail</h3>
            <ul style={{listStyleType:"none"}}>
                <li><a href="mailto:datenschutz@digitalradar-krankenhaus.de">datenschutz@digitalradar-krankenhaus.de</a></li>
            </ul>
            <h3>4.2. Kontaktaufnahme per Telefon</h3>
            <ul style={{listStyleType:"none"}}>
                <li>Konsortium Digitalradar</li>
                <li>Telefon: +49(0)40/41906-135</li>
                <li>Telefax: +49(0)40/41906-555</li>
            </ul>
            <p><br /><br /></p>
            <h2>5. Minderjährigenschutz</h2>
            <p>
              Personen unter 16 Jahren sollten ohne Zustimmung der Eltern oder Erziehungsberechtigten keine personenbezogenen Daten an uns übermitteln. 
              Wir fordern keine personenbezogenen Daten von Kindern und Jugendlichen an. Sollten die Daten zum Besuch der Website 
              <a href="www.digitalradar-krankenhaus.de">www.digitalradar-krankenhaus.de</a> erforderlich sein, werden die Daten nicht an Dritte weitergegeben.
            </p>
            <h2>6. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h2>
            <p>
              <strong>6.1.</strong> Soweit wir für die Verarbeitung personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Art. 
              6 Abs. 1 lit. a) DSGVO als Rechtsgrundlage.
            </p>
            <p>
              <strong>6.2.</strong> Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die 
              betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b) DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die 
              zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
            </p>
            <p>
              <strong>6.3.</strong> Ist die Verarbeitung zur Wahrung eines berechtigten Interesses dieses Konsortiums oder eines Dritten erforderlich 
              und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f) 
              DSGVO als Rechtsgrundlage für die Verarbeitung.
            </p>
            <p>
              <strong>6.4.</strong> Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener Daten zum Teil gesetzlich vorgeschrieben ist 
              (z.B. Steuervorschriften) oder sich auch aus vertraglichen Regelungen (z.B. Angaben zum Vertragspartner:in) ergeben kann. Mitunter kann 
              es zu einem Vertragsschluss erforderlich sein, dass eine betroffene Person uns personenbezogene Daten zur Verfügung stellt, die in der Folge 
              durch uns verarbeitet werden müssen. Die betroffene Person ist beispielsweise verpflichtet uns personenbezogene Daten bereitzustellen, 
              wenn unser Unternehmen mit ihr einen Vertrag abschließt.
            </p>
            <p>
              <strong>6.5.</strong> Ihre personenbezogenen Daten werden an die folgenden Kategorien von Dritten zur Verarbeitung weitergegeben, oder sind für diese einsehbar:
            </p>
            <ul>
              <li>Unternehmen des Konsortiums DigitalRadar</li>
              <li>Drittdienstleister zur technischen Bereitstellung der Website: Buntmacher – David Block</li>
              <li>
                Weitere Auftragsverarbeiter:
                <ul>
                  <li>o	Auth0 (zur sicheren Authentifizierung zum Login zur Erhebungsplattform, siehe auch Punkt 10.1)</li>
                </ul>
              </li>
            </ul>
            <h2>7. Erfassung von allgemeinen personenbezogenen Daten und Informationen</h2>
            <h3>7.1. Beschreibung und Umfang der Datenverarbeitung</h3>
            <p>
              Die Internetseite des Konsortiums DigitalRadar erfasst mit jedem Aufruf der Internetseite durch eine betroffene Person oder ein automatisiertes 
              System eine Reihe von allgemeinen Daten und Informationen. Diese allgemeinen Daten und Informationen werden in den Logfiles des Servers gespeichert. 
              Erfasst werden:
            </p>
            <ul>
              <li>verwendete Browsertypen und Versionen</li>
              <li>das vom zugreifenden System verwendete Betriebssystem</li>
              <li>die Internetseite, von welcher ein zugreifendes System auf unsere Internetseite gelangt (sogenannte Referrer)</li>
              <li>die Unterwebseiten, welche über ein zugreifendes System auf unserer Internetseite angesteuert werden</li>
              <li>das Datum und die Uhrzeit eines Zugriffs auf die Internetseite</li>
              <li>eine Internet-Protokoll-Adresse (IP-Adresse)</li>
              <li>der Internet-Service-Provider des zugreifenden Systems</li>
            </ul>
            <h3>7.2. Rechtsgrundlage für die Datenverarbeitung</h3>
            <p>
              Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies ist Art. 6 Abs. 1 lit. f) DSGVO.
            </p>
            <h3>7.3. Zweck der Verarbeitung</h3>
            <p>
              Bei der Nutzung dieser allgemeinen Daten und Informationen zieht das Konsortium DigitalRadar keine Rückschlüsse auf die betroffene Person. 
              Diese Informationen werden benötigt, um
            </p>
            <ul>
              <li>die Inhalte unserer Internetseite korrekt auszuliefern</li>
              <li>die Inhalte unserer Internetseite zu optimieren</li>
              <li>die dauerhafte Funktionsfähigkeit unserer informationstechnologischen Systeme und der Technik unserer Internetseite zu gewährleisten</li>
              <li>um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen Informationen bereitzustellen. Die anonymen Daten der Server-Logfiles werden getrennt von allen durch eine betroffene Person angegebenen personenbezogenen Daten gespeichert.</li>
            </ul>
            <h3>7.4. Speicherdauer</h3>
            <p>
              Die Protokolldaten (Logfiles) werden über den Zeitpunkt des Besuches hinaus für 14 Tage gespeichert und anschließend automatisch gelöscht. 
            </p>
            <h2>8. Verwendung des Registrierungsformulars</h2>
            <h3>8.1. Beschreibung und Umfang der Datenverarbeitung</h3>
            <p>
              Im Falle der Verwendung des Registrierungsformulars werden die nachfolgenden Informationen erfasst und gespeichert:
            </p>
            <ul>
              <li>IK-Nummer</li>
              <li>Standort: Bundesland, Stadt</li>
              <li>Name des Krankenhauses</li>
              <li>Name des Trägers</li>
              <li>Anschrift</li>
              <li>Vor- und Nachname</li>
              <li>E-Mail-Adresse</li>
              <li>Position</li>
            </ul>
            <h3>8.2. Rechtsgrundlage für die Datenverarbeitung</h3>
            <p>
              Rechtsgrundlage für die Verarbeitung der Daten zum Zweck der Registrierung ist Art. 6 Abs. 1 lit. a) DSGVO. Des weiteren dient die Verarbeitung 
              der Erfüllung (vor-)vertraglicher Verpflichtungen im Sinne des Art. 6 Abs. 1 lit. b DSGVO. Soweit Sie zur Teilnahme an der Evaluation gesetzlich 
              verpflichtet sind (§ 14 b KHG), beruht die Verarbeitung darüber hinaus auf Art. 6 Abs. 1 lit. e) DSGVO.
            </p>
            <h3>8.3. Zweck der Verarbeitung</h3>
            <p>
              Das Konsortium DigitalRadar benötigt diese personenbezogenen Daten, um Ihnen den Zugang zur Erhebungsplattform zu ermöglichen, Kontakt mit Ihnen 
              aufzunehmen und/oder die im weiteren Prozess erhobenen Daten dem teilnehmenden Klinikum zuzuordnen. Die eingegebenen Daten werden verschlüsselt übertragen. 
            </p>
            <h3>8.4. Speicherdauer</h3>
            <p>
              Eine Speicherung der im Rahmen der Registrierung erhobenen personenbezogenen Daten erfolgt nur so lange, wie dies erforderlich ist um Ihnen den Zugang zur 
              Erhebungsplattform zur Verfügung zu stellen oder die Einwilligungserklärung der betroffenen Person nicht widerrufen wurde. Die im Rahmen des 
              Registrierungsformulars erhobenen personen- und einrichtungsbezogenen Daten werden spätestens nach 5 Jahren gelöscht.
            </p>
            <h2>9. Kontaktaufnahme</h2>
            <h3>9.1. Beschreibung und Umfang der Datenverarbeitung</h3>
            <p>
              Sie können auf verschiedenen Wegen Kontakt zu uns aufnehmen:
            </p>
            <ul>
              <li>E-Mail</li>
              <li>Kontaktformulare auf unseren Webseiten</li>
              <li>Post</li>
              <li>Telefon</li>
            </ul>
            <p>
              Im Falle einer Kontaktaufnahme speichern wir Ihre im Zusammenhang mit der Kontaktaufnahme an uns übermittelten personenbezogenen Daten.
            </p>
            <h3>9.2. Rechtsgrundlage für die Datenverarbeitung</h3>
            <p>
             Bezieht sich Ihre Kontaktaufnahme auf einen Vertrag oder handelt es sich um eine vorvertragliche Kontaktaufnahme (Teilnahme an der Evaluation), 
             ist Art. 6 Abs. 1 lit. b DS-GVO die Rechtsgrundlage. Nehmen Sie Kontakt aufgrund der Vorgaben zur Barrierefreiheit zu uns auf, sind wir dazu gesetzlich 
             verpflichtet, weshalb die Verarbeitung Ihrer daten nach Art. 6 Abs. 1 lit c) erfolgt. In allen anderen Fällen ist die Rechtsgrundlage für die 
             Verarbeitung Ihrer Daten Art. 6 Abs. 1 lit. f DS-GVO. Es ist unser berechtigtes Interesse, Ihre Kontaktanfragen zu bearbeiten.
            </p>
            <h3>9.3. Zweck der Verarbeitung</h3>
            <p>
              Zweck der Datenverarbeitung ist die Bearbeitung Ihrer Kontaktanfrage. Die Daten werden ausschließlich zu diesem Zweck verarbeitet.
            </p>
            <h3>9.4. Speicherdauer</h3>
            <p>
              Nach vollständiger Abwicklung der Kontaktanfrage schränken wir Ihre Daten für die weitere Verarbeitung ein. Die Löschung Ihrer Daten 
              erfolgt nach Ablauf der steuer- und handelsrechtlichen Aufbewahrungsfristen. Diese betragen gemäß § 147 Abgabenordnung (AO) für Buchungsbelege 
              volle 10 Jahre und gemäß § 257 Handelsgesetzbuch (HGB) für Geschäftsunterlagen volle 6 Jahre.
            </p>
            <p>
              Sie sind nicht zur Bereitstellung Ihrer personenbezogenen Daten verpflichtet. Die Nichtbereitstellung könnte jedoch ggfs. zur Folge habe, 
              dass Sie unser Kontaktformular nicht bzw. nicht vollumfänglich nutzen können, bzw. nicht mit uns in Kontakt treten können.
            </p>
            <h2>10. Plattform</h2>
            <h3>10.1. Beschreibung und Umfang der Datenverarbeitung</h3>
            <p>
              Nach Verifizierung Ihrer Registrierung erhält der von Ihnen benannte Hauptkontakt Zugangsdaten zur Evaluationsplattform. Über den externen 
              Dienstleister auth0 (https://auth0.com) steuern wir den Authentifizierungs- und Identifizierungsprozess. Einzelheiten zur Art und Weise der 
              Verarbeitung Ihrer Daten durch den Dienstleister erfahren Sie über dessen Datenschutz-Policy (unter https://auth0.com/de/privacy). 
            </p>
            <p>
              Über die bei der Registrierung übermittelten Daten hinaus verarbeiten wir keine weiteren personenbezogenen Daten.
            </p>
            <p>
              Sie haben die Möglichkeit nach erfolgreicher Anmeldung weitere Delegationen in Ihrem Unternehmen den Zugang zur Plattform freizugeben. 
              Die Entscheidung dafür liegt bei Ihnen. Zur Freigabe müssen Sie ein weiteres Unterkonto anlegen, über das die von Ihnen übermittelten 
              personenbezogene Daten der Delegation verarbeitet werden. Eine Freigabe des Unterkontos erfolgt durch Übersendung der Zugangsdaten der 
              von Ihnen benannten Delegation per E-Mail.
            </p>
            <h3>10.2. Rechtsgrundlage</h3>
            <p>
              Siehe Nr. 8.2 dieser Erklärung
            </p>
            <h3>10.3. Zweck</h3>
            <p>
              Siehe Nr. 8.3 dieser Erklärung.
            </p>
            <h3>10.4. Speicherdauer</h3>
            <p>
              Siehe Nr. 8.4 dieser Erklärung
            </p>
            <h2>11. Verwendung von Cookies</h2>
            <p>
              <b>
              Wir verwenden ausschließlich Cookies, welche für die Nutzung der Webseiten technisch erforderlich sind. Cookies zur Reichweitenmessung oder zur 
              Nutzungsanalyse werden nicht eingesetzt. Wir verwenden Ihre durch technisch notwendige Cookies erhobenen Nutzerdaten nicht zur Erstellung von Nutzerprofilen.
              </b>
            </p>
            <p>
              Notwendige Cookies sind für den ordnungsgemäßen Betrieb unserer Webseiten technisch erforderlich. Sie stellen die technische Stabilität 
              unserer Webseiten sicher und ermöglichen sicherheits-relevante Funktionalitäten. Die eingesetzten Cookies beinhalten keine IP-Adressen oder sonstige 
              Informationen, die das Zurückverfolgen auf Ihre Person ermöglichen.
            </p>
            <p>
              Die Verarbeitung personenbezogener Daten unter Verwendung von notwendigen Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DS-GVO. 
              Mit der Verwendung technisch notwendiger Cookies bezwecken wir, die Nutzung unserer Webseiten für Sie zu vereinfachen. Einige Funktionen unserer 
              Webseiten können ohne den Einsatz von Cookies nicht angeboten werden. Für diese Funktionen ist es erforderlich, dass der Browser auch nach einem
              Seitenwechsel wiedererkannt wird. In diesen Zwecken liegt auch unser berechtigtes Interesse.
            </p>
            <p>
              Es besteht keine Möglichkeit, unsere Webseiten ohne eine solche Verarbeitung von Daten zu nutzen, d.h. Sie haben keine Widerspruchsmöglichkeit.
            </p>
            <h2>12. Routinemäßige Löschung und Sperrung von personenbezogenen Daten</h2>
            <p>
              Die/ Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene Daten der betroffenen Person nur 
              für den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist oder sofern dies durch den Europäischen Richtlinien- und Verordnungsgeber 
              oder eine andere Gesetzgeberin/ einen anderen Gesetzgeber in Gesetzen oder Vorschriften, welchen der für die Verarbeitung Verantwortliche unterliegt, 
              vorgesehen wurde.
            </p>
            <p>
              Entfällt der Speicherungszweck oder läuft eine vom Europäischen Richtlinien- und Verordnungsgeberin oder -geber oder einer anderen 
              Gesetzgeberin/ einem anderen zuständigen Gesetzgeber vorgeschriebene Speicherfrist ab, werden die personenbezogenen Daten routinemäßig und 
              entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
            </p>
            <h2>13. Prüfen Sie Ihre Daten </h2>
            <ul>
              <li>Sie können die von Ihrem Browser an unseren Server automatisch übermittelten Daten einsehen.</li>
              <li>Sie können sich mit jedem Internetbrowser anzeigen lassen, ob Cookies gesetzt werden und was sie enthalten. </li>
            </ul>
            <p>
              Detaillierte Informationen bieten der Internetauftritt der/s Bundesbeauftragten für den Datenschutz und die Informationsfreiheit und der des 
              Bundesamtes für Sicherheit in der Informationstechnik an. 
            </p>
            <h2>14. Rechte der betroffenen Person</h2>
            <p>
              Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffene bzw. Betroffener im Sinne der DSGVO. Somit stehen Ihnen folgende 
              Rechte gegenüber der/ dem Verantwortlichen zu.
            </p>
            <h3>14.1. Recht auf Bestätigung</h3>
            <p>
              Jede betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber eingeräumte Recht, von der/ dem für die Verarbeitung Verantwortlichen 
              eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden.
            </p>
            <h3>14.2. Recht auf Auskunft, Art. 15 DSGVO, § 34 BDSG</h3>
            <p>
              Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, 
              jederzeit von der/ dem für die Verarbeitung Verantwortlichen unentgeltliche Auskunft über die zu seiner Person gespeicherten personenbezogenen Daten 
              und eine Kopie dieser Auskunft zu erhalten. Diese Recht umfasst Auskunft über folgende Informationen:
            </p>
            <ul>
              <li>die Verarbeitungszwecke</li>
              <li>die Kategorien personenbezogener Daten, die verarbeitet werden</li>
              <li>die Empfänger:innen oder Kategorien von Empfängern:innen, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden</li>
              <li>falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer</li>
              <li>wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: Alle verfügbaren Informationen über die Herkunft der Daten</li>
            </ul>
            <p>
              Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob personenbezogene Daten an ein Drittland oder an eine internationale Organisation 
              übermittelt wurden. Sofern dies der Fall ist, so steht der betroffenen Person im Übrigen das Recht zu, Auskunft über die geeigneten Garantien im 
              Zusammenhang mit der Übermittlung zu erhalten.
            </p>
            <h3>14.3. Recht auf Berichtigung, Art. 16 DSGVO</h3>
            <p>
              Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, 
              die unverzügliche Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner steht der betroffenen Person das Recht zu, 
              unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten — auch mittels einer ergänzenden Erklärung — 
              zu verlangen.
            </p>
            <h3>14.4. Recht auf Löschung (Recht auf Vergessen werden), Art. 17 DSGVO</h3>
            <p>
              Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von der/ dem Verantwortlichen zu verlangen, dass die sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden. Dies ist allerdings nur möglich, wenn die ihn angehenden personenbezogenen Daten nicht mehr notwendig sind, rechtswidrig verarbeitet werden oder eine diesbezügliche Einwilligung widerrufen wurde. Es gelten die in § 35 BDSG geregelten Ausnahmen von diesem Recht.
            </p>
            <h3>14.5. Recht auf Einschränkung der Verarbeitung, Art. 18 DSGVO</h3>
            <p>
              Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von der/ dem Verantwortlichen eine weitere Verarbeitung der ihn angehenden personenbezogenen Daten vorerst zu verhindern. Eine Einschränkung tritt vor allem in der Prüfungsphase anderer Rechtewahrnehmungen durch die Betroffene bzw. den Betroffenen ein. Dies ist etwa der Fall, wenn die betroffene Person Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DSGVO eingelegt hat und es noch nicht fest steht, ob die berechtigten Gründe der/ des Verantwortlichen gegenüber denen der betroffenen Person überwiegen.
            </p>
            <h3>14.6. Recht auf Datenübertragbarkeit, Art. 20 DSGVO</h3>
            <p>
              Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die sie betreffenden personenbezogenen Daten, welche durch die betroffene Person einer/ einem Verantwortlichen bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, um sie ggf. an eine/ einen anderen Verantwortlichen weiterleiten zu lassen. Gemäß Art. 20 Abs. 3 Satz 2 DSGVO steht dieses Recht aber dann nicht zur Verfügung, wenn die Datenverarbeitung der Wahrnehmung öffentlicher Aufgaben dient.
            </p>
            <h3>14.7. Recht auf Widerspruch, 21 DSGVO</h3>
            <p>
              Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 lit. e) oder f) DSGVO erfolgt, Widerspruch einzulegen. 
            </p>
            <p>
              Das Konsortium DigitalRadar verarbeitet die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
            </p>
            <p>
              Zudem hat die betroffene Person das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, gegen die sie betreffende Verarbeitung personenbezogener Daten, die bei der Konsortium DigitalRadar zu wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DSGVO erfolgen, Widerspruch einzulegen, es sei denn, eine solche Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe erforderlich.
            </p>
            <h3>14.7.1. Recht auf Widerruf einer datenschutzrechtlichen Einwilligung, Art. 13 und 14 DSGVO</h3>
            <p>
              Soweit die Verarbeitung der personenbezogenen Daten auf Grundlage einer Einwilligung erfolgt, hat jede von der Verarbeitung personenbezogener Daten betroffene Person das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, diese Einwilligung jederzeit für den entsprechenden Zweck zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Eingang des Widerrufs erfolgten Verarbeitung nicht berührt. Ebenfalls unberührt von dem Widerruf bleibt die Rechtmäßigkeit von Verarbeitungen, die auf einer anderen Rechtsgrundlage beruhen, z. B. wenn die Verarbeitung in unseren berechtigten Geschäftsinteressen liegt.
            </p>
            <h3>14.8. Automatisierte Entscheidungen im Einzelfall einschließlich Profiling</h3>
            <p>
              Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung nach Maßgabe von Art. 22 DSGVO unterworfen zu werden.
            </p>
            <h3>14.9. Recht auf Beschwerde bei einer Aufsichtsbehörde</h3>
            <p>
              Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt, Art. 77 DSGVO.
            </p>
            <p>
              Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
            </p>
            <p>
              Nachstehend finden Sie die Kontaktangaben der zuständigen Aufsichtsbehörde:
            </p>
            <ul style={{listStyleType: "none"}}>
              <li>Berliner Beauftragte für Datenschutz und Informationsfreiheit</li>
              <li>Friedrichstraße 219</li>
              <li>10969 Berlin</li>
              <li><a href="mailto:mailbox@datenschutz-berlin.de">mailbox@datenschutz-berlin.de</a></li>
              <li><a href="https://www.datenschutz-berlin.de">https://www.datenschutz-berlin.de</a></li>
            </ul>
            <p>
              Wenn Sie eines dieser Rechte ausüben möchten, kontaktieren Sie uns bitte unter <a href="mailto:datenschutz@digitalradar-krankenhaus.de">datenschutz@digitalradar-krankenhaus.de</a>.
            </p>
          </Grid>
        </Grid>
      </div>
    );
  }
}


export default withStyles(styles)(
  (withRouter(Privacy))
);
