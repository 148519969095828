import { isEmpty } from 'lodash';

export const hasPermission = (permission, userPermissions) => {
  return true;
  //return userPermissions && userPermissions.indexOf(permission) > -1 ? true : false;
};

export const isAuthenticated = decodedJwt => {
  if (!isEmpty(decodedJwt)) {
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  return false;
};
