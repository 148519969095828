"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasDelegatePermission = void 0;
var _lodash = require("lodash");
var hasDelegatePermission = exports.hasDelegatePermission = function hasDelegatePermission() {
  var delegates = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var email = arguments.length > 1 ? arguments[1] : undefined;
  var entityID = arguments.length > 2 ? arguments[2] : undefined;
  var pageID = arguments.length > 3 ? arguments[3] : undefined;
  console.log('process.env.REACT_APP_AUTH0_USE_AUTH', process.env.REACT_APP_AUTH0_USE_AUTH);
  if (process.env.REACT_APP_AUTH0_USE_AUTH === 'false') {
    return true;
  }
  var hasPermission = false;
  var delegatesUser = (0, _lodash.find)(delegates.users, function (user) {
    return user.email.toLowerCase() == email.toLowerCase();
  });
  hasPermission = delegates.users && delegatesUser ? true : false;
  if (!hasPermission && entityID) {
    var entityDelegates = (0, _lodash.find)(delegates.entities, {
      entityID: entityID
    });
    if (entityDelegates) {
      var entityDelegatesUser = (0, _lodash.find)(entityDelegates.users, function (user) {
        //if user is not an object:
        if (typeof user === 'string') {
          return user.toLowerCase() == email.toLowerCase();
        } else {
          //its an object
          return user.email.toLowerCase() == email.toLowerCase();
        }
      });
      if (entityDelegatesUser) {
        hasPermission = true;
      } else if (!hasPermission && pageID) {
        var pageDelegates = (0, _lodash.find)(entityDelegates.categories, {
          pageID: pageID
        });
        if (pageDelegates) {
          var pageDelegateUser = (0, _lodash.find)(pageDelegates.users, function (user) {
            //if user is not an object:
            if (typeof user === 'string') {
              return user.toLowerCase() == email.toLowerCase();
            } else {
              //its an object
              return user.email.toLowerCase() == email.toLowerCase();
            }
          });
          hasPermission = pageDelegates && pageDelegateUser ? true : false;
        } else {
          hasPermission = false;
        }
      }
    }
  }
  return hasPermission;
};