export const AUTH_CONFIG = {
  //domain: 'himss.auth0.com',
  domain: process.env.REACT_APP_AUTH0_DOMAIN, //custom domain verified in auth0
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUrl: process.env.REACT_APP_AUTH0_CALLBACK ? process.env.REACT_APP_AUTH0_CALLBACK : 'http://localhost:3000/callback',
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  issuer: process.env.REACT_APP_AUTH0_TOKEN_ISSUER,
  options: {
    oidcConformant: true,
    redirect: true,
    autoclose: true,
    configurationBaseUrl: process.env.REACT_APP_AUTH0_CONFIG_BASE_URL,
    //passwordlessMethod: 'link',
    auth: {
      params: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'openid profile email',
      },
      responseType: 'token id_token',
      redirectUrl: process.env.REACT_APP_AUTH0_CALLBACK ? process.env.REACT_APP_AUTH0_CALLBACK : 'http://localhost:3000/callback',
    },
    theme: {
      logo: 'https://www.himssanalytics.org/sites/himssanalytics/files/HA-Logo.png',
      primaryColor: '#f29c1a',
    },
    languageDictionary: {
      title: 'Survey',
    },
  },
  overrides: {
    tenant: process.env.REACT_APP_AUTH0_TENANT,
    token_issuer: process.env.REACT_APP_AUTH0_TOKEN_ISSUER
  },
  logoutRedirectUrl: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URL ? process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URL : 'http://localhost:3000/login',
};

/*
export const AUTH_CONFIG = {
  //domain: 'himss.auth0.com',
  domain: process.env.REACT_APP_DOD ? 'himss-lock.us.auth0.com' : 'himss-crm.us.auth0.com', //custom domain verified in auth0
  clientId: process.env.REACT_APP_DOD ? 'KFao7wyxYZqG4anxKr62oLo1SusBUYnx' : 'ALrbBHtWjOBucxaeoLcy2g35588xG2Ej',
  options: {
    languageDictionary: {
      title: 'Survey TEST',
    },
  },
  logoutRedirectUrl: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URL ? process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URL : 'http://localhost:3000/login',
};
*/
