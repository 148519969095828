"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSettings = exports.GET_SETTINGS_SUCCESS = exports.GET_SETTINGS_REQUEST = exports.GET_SETTINGS_FAILURE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("../middleware/api");
var GET_SETTINGS_REQUEST = exports.GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST';
var GET_SETTINGS_SUCCESS = exports.GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
var GET_SETTINGS_FAILURE = exports.GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';
var getSettings = exports.getSettings = function getSettings() {
  var queryString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_SETTINGS_REQUEST, GET_SETTINGS_SUCCESS, GET_SETTINGS_FAILURE],
    authenticated: true,
    endpoint: 'SurveyAppFlags/APP_MAINTENANCE',
    method: 'GET'
  });
};