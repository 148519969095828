import { Grid, Hidden } from '@mui/material';
import { withStyles } from "@mui/styles";
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { getResource } from '@survey/common/dist/utilities/getResource';
import { getResources } from '@survey/common/dist/actions/resources.actions';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import BrowserDefaultLanguage from '@survey/common/dist/utilities/browserDefaultLanguage';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
    footerListWrapperHimss: {
        textAlign: 'right',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    footerListHimss: {
        listStyle: 'none',
        margin: '0',
        padding: '0',
        [theme.breakpoints.down('xs')]: {
            padding: '6px',
        },
    },
    footerListItemHimss: {
        display: 'inline-block',
        paddingLeft: '1em',
        paddingRight: '1em',
        '&:first-child': {
            paddingLeft: '0',
        },
    },
    footerListItemLinkHimss: {
        color: theme.palette.grey['900'],
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    footerElement: {
        backgroundColor: theme.palette.grey[100],
        padding: 6,
        position: 'fixed',
        bottom: '0',
        left: '0',
        right: '0',
        display: 'flex',
        flexDirection: 'row',
        zIndex: '1300',
    },
    footerContainer: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    footerListWrapper: {
        textAlign: 'right',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    footerList: {
        listStyle: 'none',
        margin: '0',
        padding: '0',
        [theme.breakpoints.down('xs')]: {
            padding: '6px',
        },
    },
    footerListItem: {
        display: 'inline-block',
        paddingLeft: '1em',
        paddingRight: '1em',
        '&:first-child': {
            paddingLeft: '0',
        },
    },
    footerListItemLink: {
        color: theme.palette.grey['900'],
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

class Footer extends Component {

    cookieClick = (e) => {
        e.preventDefault();
        localStorage.removeItem('tou_hidden');
        window.location.reload();
    }

    componentDidMount() {
        const { resources } = this.props;
        isEmpty(resources) && this.props.getResources();
    }

    render() {
        const { classes, resources } = this.props;
        const isGermanTenderEnvironment = process.env.REACT_APP_ENVIRONMENT === 'germantender';

        return(
            <footer className={classes.footerElement}>
                <Grid container className={classes.footerContainer}>
                    {isGermanTenderEnvironment && 
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                            <span>&copy;{ new Date().getFullYear() } <strong>Alle Rechte vorbehalten.</strong></span>
                        </Grid>
                        <Grid className={classes.footerListWrapper} item xs={12} sm={6}>
                            <ul className={classes.footerList}>
                                <li className={classes.footerListItem}><a href="https://www.digitalradar-krankenhaus.de/datenschutzerklaerung/" target="_blank" className={classes.footerListItemLink}>Datenschutzerklärung</a></li>
                                <li className={classes.footerListItem}><a href="https://www.digitalradar-krankenhaus.de/barrierefreiheitserklaerung/" target="_blank" className={classes.footerListItemLink}>Erklärung zur Barrierefreiheit</a></li>
                                <li className={classes.footerListItem}><a href="https://www.digitalradar-krankenhaus.de/impressum/" target="_blank" className={classes.footerListItemLink}>Impressum</a></li>
                                <li className={classes.footerListItem}><a href="https://www.digitalradar-krankenhaus.de/nutzungsbedingungen/" target="_blank" className={classes.footerListItemLink}>Nutzungsbedingungen</a></li>
                            </ul>
                        </Grid>
                    </Fragment>
                    }
                    {!isGermanTenderEnvironment && 
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                            <span>&copy;{ new Date().getFullYear() } Healthcare Information and Management Systems Society. All rights reserved.</span>
                        </Grid>
                        <Grid className={classes.footerListWrapperHimss} item xs={12} sm={6}>
                            <ul className={classes.footerListHimss}>
                                <li className={classes.footerListItemHimss}><a href="https://www.himss.org/himss-legal" target="_blank" className={classes.footerListItemLinkHimss}>{getResource(resources, BrowserDefaultLanguage(), 'Label', 'Privacy Policy')}</a></li>
                                <li className={classes.footerListItemHimss}><Link to="/Terms" target="_blank" className={classes.footerListItemLinkHimss}>{getResource(resources, BrowserDefaultLanguage(), 'Label', 'Terms of Use')}</Link></li>
                            </ul>
                        </Grid>
                    </Fragment>
                    }
                </Grid>
            </footer>
        );
    }
};

const mapStateToProps = (state, props) => {  
    return {
        resources: state.resources.get('resources')
    };
};
  
export default withStyles(styles)(
    connect(
        mapStateToProps,
        {getResources}
    )(withRouter(Footer))
);
