import { Grid, Hidden } from '@mui/material';
import { withStyles } from "@mui/styles";
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LeftPanel from '../../images/Left-Panel.png';
import LeftPanelDR from '../../images/Left-PanelDR_new.png';

const styles = theme => ({
  root: {
    backgroundColor: '#fff',
  },
  panel: {
    alignItems: 'center',
    backgroundImage: `url(${LeftPanel})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    paddingBottom: '2rem',
    height: '100%',
    backgroundColor: '#1526a5',
    marginRight: '1em',
    minHeight: '2564px',
  },
  drpanel: {
    backgroundImage: `url(${LeftPanelDR})`,
    backgroundColor: '#005491',
  },
});

class Imprint extends Component {
  render() {
    const { classes } = this.props;
    const isGermanTenderEnv = process.env.REACT_APP_ENVIRONMENT === 'germantender';

    return (
      <div className={classes.root}>
        <Grid container>
          <Hidden xsDown>
            <Grid item sm={3}>
              <div className={`${classes.panel} ${isGermanTenderEnv ? classes.drpanel : ''}`}></div>
            </Grid>
          </Hidden>
          <Grid item sm={9} xs={12}>
              <h1>Impressum</h1>
              <h2>Konsortium DigitalRadar</h2>
              <p>vertreten durch HIMSS Europe GmbH/inav – privates Institut für angewandte Versorgungsforschung GmbH/Lohfert &amp; Lohfert AG
                <br /><a href="mailto:info@digitalradar-krankenhaus.de">info@digitalradar-krankenhaus.de</a>
              </p>
              <h2>Konsortialmitglieder</h2>
              <h3>HIMSS Europe GmbH</h3>
              <p>Bertha-Benz-Straße 5<br />10557 Berlin<br />Deutschland</p>
              <p><a href="http://www.himss.org">www.himss.org</a><br /><a href="mailto:HIMSS_International@himss.org">HIMSS_International@himss.org</a></p>
              <p>
                Managing Directors: Harold Wolf, Bruce Steinberg, und Dr. Sebastian Krolop
                <br />Handelsregister: HRB 152823 B
                <br />VAT ID: DE272998900
              </p>
              <h3>inav – privates Institut für angewandte Versorgungsforschung GmbH</h3>
              <p>
                Schiffbauerdamm 12
                <br />10117 Berlin
                <br />Deutschland
              </p>
              <p><a href="http://www.inav-berlin.de">www.inav-berlin.de</a><br /><a href="mailto:info@inav-berlin.de">info@inav-berlin.de</a></p>
              <p>
                vertreten durch den Geschäftsführer Univ.-Prof. Dr. Volker Eric Amelung sowie die Prokuristen Ralph Lägel, MBA, und Malte Haring
              </p>
              <p>
                Handelsregister:
                <br />Amtsgericht Berlin-Charlottenburg, HRB 117730 B</p>
              <h3>Lohfert &amp; Lohfert AG</h3>
              <p>Rothenbaumchaussee 76<br />20148 Hamburg<br />Deutschland</p>
              <p><a href="http://www.lohfert.net">www.lohfert.net</a><br /><a href="mailto:info@lohfert.net">info@lohfert.net</a></p>
              <p>Vorstand: Dr. med., Dipl.-Wirtsch.-Ing. Jens Peukert (Vors.); Dr. med. Axel Kaiser (Stellv.)
                <br />Aufsichtsrat: Marcus Bracklo (Vors.), Dr. Oliver Tomat (Stellv.), Stefan Engel</p>
              <p>
                Handelsregister:
                <br />Amtsgericht Hamburg, HRB 73693
                <br />VAT ID: DE220838039
              </p>
              <h3>Verantwortlich für den Inhalt gemäß §55 Abs. 2 RStV</h3>
              <p>Konsortium DigitalRadar vertreten durch HIMSS Europe GmbH, inav - privates Institut für angewandte Versorgungsforschung GmbH und Lohfert &amp; Lohfert AG</p>
              <a href="mailto:info@digitalradar-krankenhaus.de">info@digitalradar-krankenhaus.de</a>
            </Grid>
          </Grid>
        </div>
    );
  }
}


export default withStyles(styles)(
  (withRouter(Imprint))
);
