import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import api from '@survey/common/dist/middleware/api';
import rootReducer from '../index.reducer';

const persistConfig = {
  transforms: [immutableTransform()],
  key: 'root',
  storage: storageSession,
  blacklist: ['surveys', 'auth']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = (history, preloadedState) => {
  const store = createStore(persistedReducer, preloadedState, applyMiddleware(thunk, api));
  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
