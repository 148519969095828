"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _immutable = _interopRequireDefault(require("immutable"));
var _delegates = require("../actions/delegates.actions");
var initialState = _immutable["default"].Map({
  delegates: {},
  isDelegatesLoading: false,
  isApiError: false,
  errors: [],
  isInternalUser: null
});
var _default = exports["default"] = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _delegates.GET_DELEGATES_REQUEST:
      return state.set('delegates', {}).set('errors', []).set('isApiError', false).set('isDelegatesLoading', true);
    case _delegates.GET_DELEGATES_SUCCESS:
      return state.set('delegates', action.response).set('isDelegatesLoading', false);
    case _delegates.GET_DELEGATES_FAILURE:
      return state.set('isDelegatesLoading', false).set('error', action.messages).set('isApiError', true);
    case _delegates.ADD_DELEGATE_REQUEST:
    case _delegates.REMOVE_DELEGATE_REQUEST:
    case _delegates.RESEND_DELEGATE_REQUEST:
      return state.set('errors', []).set('isDelegatesLoading', true).set('isApiError', false);
    case _delegates.ADD_DELEGATE_SUCCESS:
    case _delegates.REMOVE_DELEGATE_SUCCESS:
    case _delegates.RESEND_DELEGATE_SUCCESS:
      return state.set('isDelegatesLoading', false);
    case _delegates.ADD_DELEGATE_FAILURE:
    case _delegates.REMOVE_DELEGATE_FAILURE:
    case _delegates.RESEND_DELEGATE_FAILURE:
      return state.set('isDelegatesLoading', false).set('error', action.messages).set('isApiError', true);
    case _delegates.GET_USER_SUCCESS:
      return state.set('isInternalUser', true);
    case _delegates.GET_USER_FAILURE:
      return state.set('isInternalUser', false);
    case _delegates.SET_DELEGATES:
      return state.set('delegates', action.response);
    default:
      return state;
  }
};