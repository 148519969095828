import { Paper, Typography } from '@mui/material';
import { withStyles } from "@mui/styles";
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Logo from '../../images/HIMSS_LOGO.png';

const styles = ({ palette, breakpoints }) => ({
  root: {
    /*backgroundImage: `url(${LoginBackground})`,*/
    backgroundColor: '#fff',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%'
  },
  welcomeContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    maxWidth: '80rem',
    width: '80%'
  },
  emramLogo: {
    height: '1.5rem',
    opacity: 1,
    [breakpoints.down('sm')]: {
      height: '1rem'
    }
  },
  button: {
    border: `.125rem solid ${palette.common.white}`,
    borderRadius: '3rem',
    color: palette.common.white,
    fontSize: '2.25rem',
    height: '5.75rem',
    [breakpoints.down('sm')]: {
      height: '4rem',
      fontSize: '1.75rem'
    }
  },
  greeting: {
    color: palette.common.white,
    marginBottom: '2rem',
    marginTop: '.5rem',
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      fontSize: '1.75rem'
    }
  },
  info: {
    color: palette.common.white,
    marginBottom: '1.75rem',
    opacity: 0.6,
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      fontSize: '1.25rem'
    }
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    maxWidth: '80rem',
    padding: '3rem',
    width: '100%'
  },
  selectLogo: {
    maxWidth: '18.25rem'
  },
  selectTitle: {
    color: palette.common.white,
    [breakpoints.up('lg')]: {
      marginLeft: '9rem'
    }
  },
  surveyContainer: {
    margin: '0 auto',
    maxWidth: '50rem',
    overflow: 'auto',
    width: '100%'
  },
  whiteLogo: {
    maxWidth: '18.25rem',
    marginBottom: '4.5rem'
  },
  largeLogo: {
    maxWidth: '50rem',
    width: 'auto'
  },
  paper: {
    marginTop: '0rem',
    padding: '4rem',
    textAlign: 'center',
    boxShadow: 'none'
  }
});

class PageNotFoundContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0
    };

  }

  componentDidMount() {
  }


  render() {
    const { classes, auth, surveys } = this.props;
    const { step } = this.state;

    return (
        <div className={classes.root}>
          <div className={step === 0 ? classes.welcomeContainer : classes.selectContainer}>
            <img className={step !== 0 ? classes.whiteLogo : classes.largeLogo} src={Logo} alt="HA Logo" />
                <Fragment>
                      <Paper className={classes.paper}>
                        <Typography variant="h5">Oops! It appears the page you are looking for does not exist.</Typography>
                      </Paper>
                </Fragment>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {

  };
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        {
        }
    )(withRouter(PageNotFoundContainer))
);
