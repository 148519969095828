import { Button, Card, Typography } from '@mui/material';
import { withStyles } from "@mui/styles";
import { Person } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { SurveyPageStatuses } from '@survey/common/dist/utilities/surveyUtils';
import { getResource } from '@survey/common/dist/utilities/getResource';

const SurveyPageCard = ({ classes, viewPage, page, delegates, status, handleToggleDelegate, hasDelegatePermission, resources, survey }) => {
  const getPrompt = (status, pageProgress) => {
    if (pageProgress && pageProgress.completed) {
      return getResource(resources, survey.language, 'Label', 'Completed');
    }

    switch (status.status) {
      case SurveyPageStatuses.NOT_STARTED:
        return getResource(resources, survey.language, 'Label', 'Start');

      case SurveyPageStatuses.INCOMPLETE:
        return getResource(resources, survey.language, 'Label', 'Continue');

      case SurveyPageStatuses.COMPLETE:
        return getResource(resources, survey.language, 'Label', 'Complete');

      default:
        return getResource(resources, survey.language, 'Label', 'Start');
    }
  };

  return (
    <Card className={classes.card} onClick={() => viewPage(page.pageId)}>
      <div className={classes.cardContents}>
        <div className={classes.cardInfo}>
          <Typography variant="h6">{page.pageName}</Typography>
          <Typography type="caption" className={classes.completion}>
            {`${page.pageProgress && page.pageProgress.completed === true ? status.totalQuestions : status.totalQuestionsAnswered} ${getResource(resources, survey.language, 'Label', 'of')} ${
              status.totalQuestions
            } ${getResource(resources, survey.language, 'Label', 'questions')}`}
          </Typography>
        </div>
        <div className={classes.statusWrapper}>
          <Fragment>
            {/*{hasDelegatePermission && (
                  <Button
                      color="primary"
                      variant={isEmpty(delegates) || delegates.length === 0 ? 'contained' : 'outlined'}
                      className={classes.delegateButton}
                      onClick={event => {
                          event.stopPropagation();

                          handleToggleDelegate();
                      }}
                  >
                      <Person style={{ marginRight: '.25rem' }} />
                      {!isEmpty(delegates) ? `${delegates.length} ${delegates.length === 1 ? 'DELEGATE' : 'DELEGATES'}` : 'DELEGATE'}
                  </Button>
              )}*/}
            <Button
              className={classes.button}
              color="primary"
              variant={page.pageProgress && page.pageProgress.completed === true ? 'outlined' : 'contained'}
              onClick={() => {
                viewPage(page.pageId);
              }}
            >
              {getPrompt(status, page.pageProgress)}
            </Button>
          </Fragment>
        </div>
      </div>
    </Card>
  );
};

const styles = () => ({
  card: {
    padding: '1rem',
    marginTop: '1rem',
  },
  cardContents: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardInfo: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem',
    '& button': {
      marginRight: '1rem',
    },
    '& button:last-of-type': {
      marginRight: '0rem',
    },
  },
  completion: {
    marginRight: '1rem',
  },
  button: {
    minWidth: '5.4rem',
    padding: '.375rem .75rem',
  },
});

SurveyPageCard.propTypes = {
  delegates: PropTypes.array.isRequired,
  handleToggleDelegate: PropTypes.func.isRequired,
  hasDelegatePermission: PropTypes.bool.isRequired,
  page: PropTypes.object.isRequired,
  permissions: PropTypes.array,
  status: PropTypes.object.isRequired,
  viewPage: PropTypes.func.isRequired,
  survey: PropTypes.object.isRequired,
};

export default withStyles(styles)(SurveyPageCard);
