import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import DocumentTitleFormatter from '../../utilities/documentTitleFormatter';
import MapRouteTitle from '../../utilities/mapRouteTitle';
import { withRouteOnEnter } from '../../utilities/withRouteOnEnter.component';
import Auth from '../auth/auth';
import { clearAuth, setAuth } from '../auth/auth.actions';
import { isAuthenticated } from '../auth/authOperations';
import Welcome from '../auth/welcome.component';
import Callback from '../callback/callback';
import SurveyLanding from '../surveys/landing/surveyLanding.container';
import Toast from './toast.container';
import LoadingOverlay from '../layout/loadingOverlay.component';
import NotFound from '../layout/404.container';
import Imprint from '../layout/imprint.container';
import Terms from '../layout/terms.container';
import Privacy from '../layout/privacy.container';
import Footer from './footer.component';
import { EventBus } from '@survey/common/dist/utilities/EventBus';
const styles = (theme) => ({
  root: {
    height: '100%',
  },
  mainContent: {
    height: '100%',
  },
});

class MainContainer extends Component {
  constructor(props) {
    super(props);

    //this.auth = new Auth(this.props.location.search === '?internal' ? true : false, this.props.history, props.setAuth, props.clearAuth);
    this.auth = new Auth(this.props.history, props.setAuth, props.clearAuth);

    this.checkAuth = this.checkAuth.bind(this);

    //set a variable to control if we are using auth0 logins or not.
    this.useAuth = false;

    let rawIdToken = localStorage.getItem('id_token');
    props.setAuth(rawIdToken);
    
    if (process.env.REACT_APP_AUTH0_USE_AUTH === 'true') {
      this.useAuth = true;
    }
  }

  componentDidMount() {
    if (this.props.location.pathname !== '/login' && this.props.location.pathname !== '/callback') {
      localStorage.setItem('redirect_uri', this.props.location.pathname);
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'germantender') {
      document.body.style.setProperty('font-family', "'Century Gothic', CenturyGothic, AppleGothic, sans-serif", 'important');
    }

    EventBus.on('LogOff', () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove('LogOff');
  }

  logOut() {
    this.auth.logout();
  }

  checkAuth() {
    if (!this.useAuth) {
      return;
    }

    if (isAuthenticated(this.props.tokenInfo)) {
      // NOTE: Right here you can read from the user's profile to determine if they have what they need to proceed
      if (this.props.location.pathname === '/login') {
        this.props.history.replace('/home');
      }
    } else {    
        this.auth.login();
    }
  }

  render() {
    const { classes, authSet, tokenInfo, location } = this.props;
    const isGermanTenderEnvironment = process.env.REACT_APP_ENVIRONMENT === 'germantender';

    //Setting a White Label product title based on enviornments.
    var whiteLabelName = ({   
      "himss"        : "HIMSS Survey",
      "dod"          : "HIMSS Survey",
      "germantender" : "Digital Radar",
    })[process.env.REACT_APP_ENVIRONMENT];

    document.title = DocumentTitleFormatter(MapRouteTitle(location), whiteLabelName);
    
    return (
      <Fragment>
        {!authSet ? (
          <LoadingOverlay />
        ) : (
          <div className={classes.root}>
            <div className={classes.mainContent}>
              {this.useAuth ? (
                isAuthenticated(tokenInfo) ? (
                  <Switch>
                    <Route exact path="/home" component={withRouteOnEnter(this.checkAuth)(Welcome, this.props)} />
                    <Route path="/surveyLanding/:id" component={withRouteOnEnter(this.checkAuth)(SurveyLanding, this.props)} />
                    <Route path="/imprint" component={Imprint}></Route>
                    <Route path="/terms" component={Terms}></Route>
                    <Route path="/privacy" component={Privacy}></Route>
                    <Redirect from="*" to="/home" />
                  </Switch>
                ) : (
                  <Switch>
                    <Route path="/login" render= {(props)=>this.auth.login() } />
                    <Route
                      exact
                      path="/callback"
                      render={(props) => {
                        this.auth.handleAuthentication(props);
                        return <Callback {...props} />;
                      }}
                    />
                    <Route path="/imprint" component={Imprint}></Route>
                    <Route path="/terms" component={Terms}></Route>
                    <Route path="/privacy" component={Privacy}></Route>
                    
                    <Redirect from="*" to="/login" />
                  </Switch>
                )
              ) : (
                <Switch>
                  <Route path="/surveyLanding/:id" component={withRouteOnEnter(this.checkAuth)(SurveyLanding, this.props)} />
                  <Route path="/imprint" component={Imprint}></Route>
                  <Route path="/terms" component={Terms}></Route>
                  <Route path="/privacy" component={Privacy}></Route>
                  <Route component={NotFound} />
                </Switch>
              )}
            </div>
            <Toast />
            <Footer />
          </div>
        )}
      </Fragment>
    );
  }
}

MainContainer.propTypes = {
  // Injected by React Router
  children: PropTypes.node,
};

const mapStateToProps = (state) => {
  return {
    authSet: state.auth.get('authSet'),
    tokenInfo: state.auth.get('tokenInfo'),
    roles: state.auth.get('roles'),
    permissions: state.auth.get('permissions'),
  };
};

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, {
      setAuth,
      clearAuth,
    })(MainContainer)
  )
);
