const MapRouteTitle = location => {
  let pathname = location.pathname;

  function locate(route) {
    return pathname.indexOf(route) !== -1;
  }

  if (locate('home')) {
    return 'Home';
  } else if (locate('login')) {
    return 'Login';
  }

  return 'Home';
};

export default MapRouteTitle;
