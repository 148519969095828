import { Paper, Typography } from '@mui/material';
import { withStyles } from "@mui/styles";
import { isEmpty } from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DigitalRadarLogoWhite from '../../images/digitalradar_logo_white_sm.png';
import DigitalRadarLogo from '../../images/digitalradar_logo.png';
import SurveyCard from './surveyCard.component';
import LoadingOverlay from '../layout/loadingOverlay.component';

import { getUserSurveys } from '@survey/common/dist/actions/surveys.actions';
import { getSettings } from '@survey/common/dist/actions/settings.actions';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';
import { getResource } from '@survey/common/dist/utilities/getResource';
import { getResources } from '@survey/common/dist/actions/resources.actions';
import BrowserDefaultLanguage from '@survey/common/dist/utilities/browserDefaultLanguage';

const styles = ({ palette, breakpoints }) => ({
  root: {
    //backgroundImage: `url(${LoginBackground})`,
    backgroundColor: '#1a25ab',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%',
  },
  digitalRadarRoot: {
    backgroundColor: '#005596',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%'
  },
  welcomeContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    maxWidth: '80rem',
    width: '80%',
  },
  emramLogo: {
    height: '1.5rem',
    opacity: 1,
    [breakpoints.down('sm')]: {
      height: '1rem',
    },
  },
  button: {
    border: `.125rem solid ${palette.common.white} !important`,
    borderRadius: '3rem !important',
    color: `${palette.common.white} !important`,
    fontSize: '2.25rem !important',
    height: '5.75rem !important',
    marginTop: '4rem !important',
    [breakpoints.down('sm')]: {
      height: '4rem !important',
      fontSize: '1.75rem !important',
    },
  },
  greeting: {
    color: palette.common.white,
    marginBottom: '2rem',
    marginTop: '.5rem',
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
  },
  info: {
    color: palette.common.white,
    marginBottom: '1.75rem',
    opacity: 0.6,
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    maxWidth: '80rem',
    padding: '3rem',
    width: '100%',
  },
  selectLogo: {
    maxWidth: '18.25rem',
  },
  selectWelcome: {
    color: palette.common.white,
    paddingTop: '1rem',
    paddingBottom: '4.5rem',
  },
  selectTitle: {
    color: palette.common.white,
    [breakpoints.up('lg')]: {
      marginLeft: '9rem',
    },
  },
  surveyContainer: {
    margin: '0 auto',
    overflow: 'auto',
    width: '100%',
  },
  whiteLogo: {
    maxWidth: '18.25rem',
  },
  largeLogo: {
    maxWidth: '50rem',
    width: 'auto',
  },
  paper: {
    marginTop: '8rem',
    padding: '4rem',
    textAlign: 'center',
  },
  digitalRadarLogo: {
    flexGrow: 1,
    width: '72rem',
    marginBottom: '2rem'
  }
});

class Welcome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assessmentsLoaded: false,
      browserLanguage: BrowserDefaultLanguage(),
    };

    this.directUser = this.directUser.bind(this);
  }

  componentDidMount() {
    const { resources } = this.props;
    isEmpty(resources) && this.props.getResources();
    isEmpty(this.props.surveys) &&
      this.props.getUserSurveys()
        .then(() => this.setState({ assessmentsLoaded: true }))
        .then(() => this.directUser());

    this.props.getSettings().then((response) => {
      this.setState({ maintenance: response });
    });
  }

  directUser() {
    const { surveys, history } = this.props;

    let redirect_uri = localStorage.getItem('redirect_uri');
    
    if (redirect_uri && redirect_uri.indexOf('surveyLanding') !== -1) {
      localStorage.removeItem('redirect_uri');
      history.push(redirect_uri);
    } else if (surveys && surveys.length === 1 && window.location.pathname.indexOf('surveyLanding') === -1) {
      history.push(`surveyLanding/${surveys[0].surveyGuid}/home`);
    }
  }

  render() {
    const { classes, resources, surveys } = this.props;
    const isGermanTenderEnvironment = process.env.REACT_APP_ENVIRONMENT === 'germantender';
    let maintenanceMode = false;
    let logoToUse = 'https://ha-static-content.s3.us-east-1.amazonaws.com/images/HIMSSlogo_HwhiteLogotype_RGB.png';
    let logoClass = classes.largeLogo;
    let rootClass = classes.root;

    if (isGermanTenderEnvironment) {
      logoToUse = DigitalRadarLogoWhite;
      logoClass = classes.digitalRadarLogo;
      rootClass = classes.digitalRadarRoot;
    }

    if (!isEmpty(this.state.maintenance)) {
      maintenanceMode = this.state.maintenance.response && true;
    }

    return (
      <div className={rootClass}>
        <div className={ classes.selectContainer }>
          <img className={ classes.whiteLogo } src={logoToUse} alt="HA White Logo" />
          {isGermanTenderEnvironment && (
            <Typography variant="h4" className={classes.selectWelcome}>
              Willkommen auf der DigitalRadar-Erhebungsplattform
            </Typography>
          )}
          {!isGermanTenderEnvironment && (
            <Typography variant="h4" className={classes.selectWelcome}>
              {getResource(resources, this.state.browserLanguage, 'Message', 'Welcome to the HIMSS assessment platform')}
            </Typography>
          )}
          {!maintenanceMode && (
            <Fragment>
              {!this.state.assessmentsLoaded && (<LoadingOverlay /> )}
              {surveys && surveys.length > 1 && (
                <Fragment>
                  <Typography variant="h5" className={classes.selectTitle}>
                    {getResource(resources, this.state.browserLanguage, 'Message', 'Please Select Assessment:')}
                  </Typography>
                  <div className={classes.surveyContainer}>
                    {surveys.map((survey, index) => (
                      <SurveyCard key={`survey-${index}`} survey={survey} browserLanguage={this.state.browserLanguage} resources={this.props.resources} />
                    ))}
                  </div>
                </Fragment>
              )}
              {this.state.assessmentsLoaded && surveys && surveys.length === 0 && (
                <Paper className={classes.paper}>
                  <Typography variant="h5">{getResource(resources, this.state.browserLanguage, 'Message', 'You currently have no assessment assigned.')}</Typography>
                </Paper>
              )}
            </Fragment>
          )}
        </div>
        <Confirm
          title={getResource(resources, this.state.browserLanguage, 'Message', 'Site Maintenance Period')}
          contentText={
            getResource(resources, this.state.browserLanguage, 'Message', 'We apologize for the inconvenience. The site is currently under scheduled maintenance and upgrades but will return shortly. Thank you for your patience. For questions or concerns please contact customerservice@himss.org')
          }
          open={maintenanceMode}
          buttons="confirm-only"
          confirmText={getResource(resources, this.state.browserLanguage, 'Label', 'Confirm')}
          onConfirm={() => (window.location.href = 'https://www.himss.org')}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth.get('tokenInfo'),
    surveys: state.surveys.get('mySurveys'),
    resources: state.resources.get('resources'),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    getUserSurveys,
    getSettings,
    getResources,
  })(withRouter(Welcome))
);
