"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var BrowserDefaultLanguage = function BrowserDefaultLanguage() {
  var osLangISO = window.navigator.language.split('-')[0]; // eg 'en'

  var availableLanguages = {
    "en": "English",
    "zh": "Chinese",
    "fr": "French",
    "de": "German",
    "id": "Indonesian",
    "it": "Italian",
    "ja": "Japanese",
    "ko": "Korean",
    "pl": "Polish",
    "pt": "Portuguese",
    "es": "Spanish",
    "tr": "Turkish",
    "ro": "Romanian"
  };
  return availableLanguages[osLangISO];
};
var _default = exports["default"] = BrowserDefaultLanguage;