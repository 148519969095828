import { SelectionState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { Button, Typography } from '@mui/material';
import { withStyles } from "@mui/styles";
import classNames from 'classnames';
import { Person } from '@mui/icons-material';
import { cloneDeep, find, findIndex, hasIn, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AMAMLogo from '../../../images/AMAM-Logo.png';
import EMRAMLogo from '../../../images/EMRAM-Logo.png';
import OEMRAMLogo from '../../../images/O-EMRAM-Logo.png';
import INFRAMLogo from '../../../images/INFRAM-Logo.png';
import CISOMLogo from '../../../images/CISOM-Logo.png';
import DIAMLogo from '../../../images/DIAM-Logo.png';
import DHILogo from '../../../images/DHI_Logo_Long.png';
import DRLogo from '../../../images/digitalradar_logo.png';
import { CREATE_DELEGATES } from '../../auth/permissions';
import { hasPermission } from '../../auth/authOperations';
import DelegateModal from '@survey/common/dist/components/delegates/delegateModal.component';
import { addDelegate, removeDelegate, resendDelegate, setDelegates } from '@survey/common/dist/actions/delegates.actions';
import { createSurveyStateForEntity } from '@survey/common/dist/utilities/createSurveyState';
import { hasDelegatePermission } from '@survey/common/dist/utilities/delegates';
import { calculatePageStatus } from '@survey/common/dist/utilities/surveyUtils';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';
import ConfirmSummary from '@survey/common/dist/components/dialogs/Confirm';
import { ActionsColumn } from '@survey/common/dist/components/tables/actionsColumn';
import { ProgressBar } from '@survey/common/dist/components/tables/progressBar';
import { getResource } from '@survey/common/dist/utilities/getResource';

class Entities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'entityName', title: 'Name' },
        { name: 'progress', title: 'Progress' },
        { name: 'score', title: 'Score' },
      ],
      entity: {},
      showConfirmDialog: false,
      showConfirmOnlyDialog: false,
      open: false,
      selection: [],
      survey: this.props.survey,
      resources: this.props.resources,
    };

    ['changeSelection', 'handleDelegateModalClose', 'handleRemoveDelegate', 'handleResendDelegate', 'handleSendDelegates', 'getEntityProgress'].map((name) => (this[name] = this[name].bind(this)));
  }

  changeSelection(selection) {
    console.log('this.props.entities: ', this.props.entities);
    console.log('selection: ', selection);

    const { permissions } = this.props;

    switch (selection.length) {
      case 0:
        break; //do nothing because after calling this function once, selection is nothing! Call it again and it has a value again! WTF
      default:
        if (this.props.survey.status === 'Completed' || this.props.survey.status === 'Submitted to HA' || this.props.survey.status === 'In Review') {
          console.log('selection:', this.props.entities[selection].status);
          this.setState({ showConfirmDialog: true });
        } else {
          let surveyID = this.props.match.params.id;
          let entityID = this.props.entities[selection].entityID;
          this.props.history.replace(`/surveyLanding/${surveyID}/entity/${entityID}`);
        }
    }

    //Always clear grid selection
    this.setState({ selection: [] });
  }

  getEntityProgress(entity) {
    const { survey, surveyType, pages, tabs, technologies, resources } = this.props;

    let totalQuestionsAnswered = 0;
    let totalQuestions = 0;

    if (!isEmpty(survey) && !isEmpty(surveyType) && !isEmpty(pages)) {
      //const surveyState = createSurveyState(survey, surveyType, entity.haEntityTypeID, pages, tabs, technologies);
      const surveyState = createSurveyStateForEntity(entity, survey, surveyType, entity.haEntityTypeID, pages, tabs, technologies, resources);
      const pagesWithProgress = surveyState.pages.map((p) => {
        if (survey.pageProgress && survey.pageProgress[`${entity.entityID}-${p.pageId}`]) {
          p.pageProgress = survey.pageProgress[`${entity.entityID}-${p.pageId}`];
        } else {
          p.pageProgress = {};
        }
        return p;
      });

      pagesWithProgress.forEach((p) => {
        const { surveyType, tabs } = surveyState;
        const pageStatus = calculatePageStatus(`${p.pageId}`, entity, tabs, surveyType, survey, technologies);

        totalQuestions += pageStatus.totalQuestions;

        if (p.pageProgress && p.pageProgress.completed === true) {
          totalQuestionsAnswered += pageStatus.totalQuestions;
        } else {
          totalQuestionsAnswered += pageStatus.totalQuestionsAnswered;
        }
      });
    }

    const progress = totalQuestions > 0 ? totalQuestionsAnswered / totalQuestions : 0;
    return Math.floor(progress * 100) / 100;
  }

  getCells() {
    const { surveyType } = this.props;
    let Logo;

    switch (surveyType.scoringType) {
      case 'AMAM':
        Logo = AMAMLogo;
        break;

      case 'AMAM 2024':
        Logo = AMAMLogo;
        break;

      case 'EMRAM':
        Logo = EMRAMLogo;
        break;

      case 'DIAM':
        Logo = DIAMLogo;
        break;

      case 'O-EMRAM':
        Logo = OEMRAMLogo;
        break;

      case 'INFRAM':
        Logo = INFRAMLogo;
        break;

      case 'CISOM':
        Logo = CISOMLogo;
        break;

      case 'DHI':
        Logo = DHILogo;
        break;

      case 'DHIShort':
        Logo = DHILogo;
        break;
      case 'DHI for CCMM':
        Logo = DHILogo;
        break;
      default:
        Logo = 'https://ha-static-content.s3.us-east-1.amazonaws.com/images/HIMSSlogo_Hfullcolor.png';
    }

    //DHI Outcomes does not habe a scoring type so we need to check name
    if (surveyType.surveyTypeID === 62) {
      Logo = DHILogo;
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'germantender') {
      Logo = DRLogo;
    }

    return (props) => {
      const { column } = props;

      const currEntity = find(this.props.entities, { entityID: props.row.entityID });

      const surveyLanguage = this.props.survey.language;

      const entityProgress = this.getEntityProgress(currEntity);

      switch (column.name) {
        case 'progress':
          return (
            <Table.Cell className="ProgressBar">
              <ProgressBar value={entityProgress} style={{ width: '300px', borderBottom: 0 }} />
            </Table.Cell>
          );
        case 'score':
          //for Outpatient 6 and 7 dont display the score or make score api call, but keep scoringType in data so that these are scored on submit (just o real time scoring)
          if (!isEmpty(surveyType) && surveyType.scoringType !== 'None' && surveyType.name !== 'Outpatient Stage 7' && surveyType.surveyTypeID !== 12) {
              return (
                <Table.Cell className="mobicell" {...props}>
                  <div className="mobiflexdiv">
                    <img
                      className="scorelogo"
                      style={
                        surveyType.scoringType === 'DHI' ||
                        surveyType.scoringType === 'DHI for CCMM' ||
                        surveyType.scoringType === 'DHIShort' ||
                        surveyType.surveyTypeID === 62 ||
                        surveyType.scoringType === 'GDHP'
                          ? {
                              height: '2rem',
                              marginRight: '2rem',
                            }
                          : {
                              height: '3.5rem',
                              marginRight: '2.5rem',
                            }
                      }
                      src={Logo}
                      alt="score logo"
                    />
                  </div>
                </Table.Cell>
              );
         }
          if (
            (!isEmpty(surveyType) && surveyType.name === 'Stage 7 Prevalidation Survey') ||
            surveyType.name === 'Outpatient Stage 7' ||
            surveyType.surveyTypeID === 12 ||
            surveyType.surveyTypeID === 62 ||
            surveyType.surveyTypeID === 72
          ) {
            return (
              <Table.Cell className="mobicell" {...props}>
                <div className="mobiflexdiv">
                  <img
                    className="scorelogo"
                    style={
                      surveyType.surveyTypeID === 62
                        ? {
                            height: '2rem',
                            marginRight: '2rem',
                          }
                        : {
                            height: '3.5rem',
                            marginRight: '2.5rem',
                          }
                    }
                    src={Logo}
                    alt="score logo"
                  />
                </div>
              </Table.Cell>
            );
          }
          return <Table.Cell className="mobicell" {...props} />;

        default:
          return <Table.Cell className="mobicell" {...props} />;
      }
    };
  }

  addEntityProgress(entity) {
    const { survey, surveyType, pages, tabs, technologies, resources } = this.props;

    let totalQuestionsAnswered = 0;
    let totalQuestions = 0;

    if (!isEmpty(survey) && !isEmpty(surveyType) && !isEmpty(pages)) {
      //const surveyState = createSurveyState(survey, surveyType, entity.haEntityTypeID, pages, tabs, technologies);
      const surveyState = createSurveyStateForEntity(entity, survey, surveyType, entity.haEntityTypeID, pages, tabs, technologies, resources);
      const pagesWithProgress = surveyState.pages.map((p) => {
        if (survey.pageProgress && survey.pageProgress[`${entity.entityID}-${p.pageId}`]) {
          p.pageProgress = survey.pageProgress[`${entity.entityID}-${p.pageId}`];
        }

        return p;
      });

      pagesWithProgress.forEach((p) => {
        const { surveyType, tabs } = surveyState;
        const pageStatus = calculatePageStatus(`${p.pageId}`, entity, tabs, surveyType, survey, technologies);

        totalQuestions += pageStatus.totalQuestions;

        if (p.pageProgress && p.pageProgress.completed === true) {
          totalQuestionsAnswered += pageStatus.totalQuestions;
        } else {
          totalQuestionsAnswered += pageStatus.totalQuestionsAnswered;
        }
      });
    }

    const progress = totalQuestions > 0 ? totalQuestionsAnswered / totalQuestions : 0;

    return { ...entity, progress };
  }

  handleDelegateModalClose() {
    this.setState({ open: false, entity: {} });
  }

  async handleSendDelegates(newDelegates, sendPlainText) {
    const { addDelegate, survey, delegates, setDelegates } = this.props;

    console.log('in handleSendDelegates in entities.component, sendPlainText: ', sendPlainText);

    const delegatesClone = cloneDeep(delegates);

    for (const [index, d] of newDelegates.entries()) {
      const response = await addDelegate(survey.surveyID, d, sendPlainText);

      if (response.type === 'ADD_DELEGATE_SUCCESS') {
        if (!isEmpty(delegatesClone.entities)) {
          const entityIndex = findIndex(delegatesClone.entities, { entityID: d.entityID });

          if (entityIndex !== -1) {
            delegatesClone.entities[entityIndex].users.push(d);
          } else {
            delegatesClone.entities.push({ entityID: d.entityID, users: [d], categories: [] });
          }
        } else {
          delegatesClone.entities = [{ entityID: d.entityID, users: [d], categories: [] }];
        }

        if (index + 1 === newDelegates.length) {
          await setDelegates(delegatesClone);
        }
      }
    }

    this.setState({ open: false, entity: {} });
  }

  async handleRemoveDelegate(delegate) {
    const { survey, removeDelegate, delegates, setDelegates } = this.props;
    const delegatesClone = cloneDeep(delegates);

    //delete delegate.accessDelegated;
    //delete delegate.pageID;
    console.log('EsC removing delegate: ', delegate);
    console.log('EsC survey.surveyID: ', survey.surveyID);
    const response = await removeDelegate(survey.surveyID, delegate);

    if (response.type === 'REMOVE_DELEGATE_SUCCESS') {
      if (!hasIn(delegate, 'entityID')) {
        const userIndex = findIndex(delegates.users, { email: delegate.email });

        delegatesClone.users.splice(userIndex, 1);
        await setDelegates(delegatesClone);
      } else if ('pageID' in delegate) {
        const entityIndex = findIndex(delegatesClone.entities, { entityID: delegate.entityID });
        const pageIndex = findIndex(delegatesClone.entities[entityIndex].categories, { pageID: delegate.pageID });
        const userIndex = findIndex(delegatesClone.entities[entityIndex].categories[pageIndex].users, { email: delegate.email });

        delegatesClone.entities[entityIndex].categories[pageIndex].users.splice(userIndex, 1);
      } else if ('entityID' in delegate) {
        const entityIndex = findIndex(delegatesClone.entities, { entityID: delegate.entityID });
        const userIndex = findIndex(delegatesClone.entities[entityIndex].users, { email: delegate.email });

        delegatesClone.entities[entityIndex].users.splice(userIndex, 1);
      }
    }

    await setDelegates(delegatesClone);
  }

  async handleResendDelegate(delegate, delegateModalDelegates, delegateModalSurvey, plainText) {
    const { survey, resendDelegate } = this.props;

    console.log('in handleResendDelegate in entities.component, sendPlainText: ', plainText);

    delete delegate.accessDelegates;
    const response = await resendDelegate(survey.surveyID, delegate, plainText);

    if (response.type === 'RESEND_DELEGATE_SUCCESS') {
      console.log('entities.component, DELEGATE RESEND SUCCESS');
      this.setState({ showConfirmOnlyDialog: true });
    }
  }

  render() {
    const { columns, open, entity } = this.state;
    const { survey, auth, delegates, classes, permissions, resources } = this.props;

    const entities = this.props.entities.map((entity) => {
      const newEntity = { ...entity };

      newEntity.delegates = find(delegates.entities, { entityID: entity.entityID });
      newEntity.hasDelegatePermission = hasPermission(CREATE_DELEGATES, permissions) || hasDelegatePermission(delegates, auth.email, entity.entityID);
      newEntity.email = auth.email;

      return this.addEntityProgress(newEntity);
    });

    const actions = [
      {
        component: <Button className={classNames(classes.actionButtons, 'mobifullwidth', 'mobibutton')} key="status" color="primary" variant="contained" style={{ minHeight: '2.25rem' }} />,
        type: 'status',
        action: () => console.log('Changing Entity Selection'),
      },
    ];

    actions.push({
      component: <Button className={classes.actionButtons} key="delegate" color="primary" variant="contained" style={{ minHeight: '2.25rem', marginRight: '1rem' }} />,
      type: 'delegate',
      icon: <Person key="icon" style={{ marginRight: '.25rem' }} />,
      action: (event, rowId) => {
        event.stopPropagation();

        this.setState({ open: true, entity: entities[rowId] });
      },
    });

    var cwsEmailValue = 'customerservice@himss.org';
    if (process.env.REACT_APP_DOD) {
      cwsEmailValue = 'himss4dha@himssanalytics.org';
    }

    return (
      <Fragment>
        <Grid rows={entities} columns={columns}>
          <SelectionState selection={this.state.selection} onSelectionChange={this.changeSelection} />
          <Table cellComponent={this.getCells()} />
          <TableSelection selectByRowClick showSelectionColumn={false} />
          <ActionsColumn actions={actions} width={300} resources={resources} language={survey.language} pageProgress={survey.pageProgress} />
        </Grid>
        <DelegateModal
          auth={auth}
          delegates={delegates}
          handleClose={this.handleDelegateModalClose}
          handleRemove={this.handleRemoveDelegate}
          handleResend={this.handleResendDelegate}
          handleSend={this.handleSendDelegates}
          open={open}
          survey={survey}
          entity={entity}
          resources={resources}
          language={survey.language}
        />
        <Confirm
          title={getResource(resources, survey.language, 'Message', 'Email Resent to Delegate')}
          buttons="confirm-only"
          onClose={() => this.setState({ showConfirmOnlyDialog: false })}
          onConfirm={() => this.setState({ showConfirmOnlyDialog: false })}
          contentText={getResource(resources, survey.language, 'Message', 'An email has been re-sent to the delegate.')}
          open={this.state.showConfirmOnlyDialog}
          confirmText={getResource(resources, survey.language, 'Label', 'Confirm')}
        />
        <ConfirmSummary
          title={getResource(resources, survey.language, 'Message', 'Survey has already been Submitted.')}
          buttons="confirm-only"
          onClose={() => this.setState({ showConfirmDialog: false })}
          onConfirm={() => this.setState({ showConfirmDialog: false })}
          contentText={getResource(
            resources,
            survey.language,
            'Message',
            'This survey has been completed and data can no longer be modified. To make additional changes please contact us at ' + cwsEmailValue + '.'
          )}
          open={this.state.showConfirmDialog}
          confirmText={getResource(resources, survey.language, 'Label', 'Confirm')}
        />
      </Fragment>
    );
  }
}

const styles = () => ({
  actionButtons: {
    cursor: 'pointer',
    float: 'right',
    padding: '.375rem .75rem',
  },
});

Entities.propTypes = {
  auth: PropTypes.object.isRequired,
  delegates: PropTypes.object.isRequired,
  entities: PropTypes.array.isRequired,
  pages: PropTypes.array.isRequired,
  permissions: PropTypes.array,
  survey: PropTypes.object.isRequired,
  surveyType: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  technologies: PropTypes.array.isRequired,
};

const mapStateToProps = (state, props) => ({});

export default withStyles(styles)(
  connect(mapStateToProps, {
    addDelegate,
    removeDelegate,
    resendDelegate,
    setDelegates,
  })(withRouter(Entities))
);
